import axios, { type AxiosResponse } from 'axios';

import { auth0 } from './auth0';

export { type Plato } from '@/types';
/**
 * TODO: Limit the scope of this type.
 */
export { type AxiosRequestConfig as PlatoRequestConfig } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const STORAGE_AUTH = 'GRAPH_VIZ_AUTH';

export const plato = axios.create({
  baseURL: API_URL,
});

async function onPlatoException(response: AxiosResponse) {
  if (response.status === 401) {
    window.location.href = `${window.location.origin}/401`;
  }

  return Promise.reject(response);
}

plato.interceptors.request.use(
  async function onPlatoRequest(config) {
    /**
     * TODO: Implement retry logic.
     */
    const authorization = await auth0.getTokenSilently();

    if (!authorization) {
      console.warn('Failed to authenticate.');
      /**
       * This is a temporary workaround to force to retry the request
       * if the authorization fails.
       *
       * TODO: Remove after implementing retry logic.
       */
      window.location.reload();

      return config;
    }

    const authToken = localStorage.getItem(
      STORAGE_AUTH,
    );

    if (!authToken) {
      const user = await auth0.getIdTokenClaims();

      localStorage.setItem(
        STORAGE_AUTH,
        user.email,
      );
    }

    config.headers['OUTROPY_API_KEY'] = authToken;
    config.headers['Authorization'] = `Bearer ${authorization}`;

    if (localStorage.getItem('AKA_TOKEN')) {
      config.headers['aka_token'] = localStorage.getItem('AKA_TOKEN');
    }

    return config;
  },
  onPlatoException,
);

plato.interceptors.response.use(
  async function onPlatoResponse(response) {
    return response;
  },
  onPlatoException,
);

export function translateProps<
  Params extends Record<string, unknown>,
>(
  params: Params,
  translations: Record<keyof Params, string>,
) {
  return Object.entries(params).reduce(
    (acc, [key, value]) => {
      acc[translations?.[key] ?? key] = value;

      return acc;
    },
    {} as {
      /**
       * Fix inference issue.
       */
      [K in (typeof translations)[keyof typeof translations]]: Params[keyof typeof translations]
    }
  );
}
