import { createApi, type BaseQueryFn } from '@reduxjs/toolkit/query/react';

import {
  plato,
  type Plato,
  type PlatoRequestConfig,
} from '@/services/plato';

const baseQuery = (): BaseQueryFn<PlatoRequestConfig, unknown, unknown> => plato;

enum TagTypes {
  SETTINGS_APPLICATION = 'SETTINGS_APPLICATION',
}

export const settings = createApi({
  reducerPath: 'settings',
  baseQuery: baseQuery(),
  tagTypes: [TagTypes.SETTINGS_APPLICATION],
  endpoints(builder) {
    const getApiKeys = builder.query<Plato.API.GetSettingsApiKeysResponseData, void>({
      providesTags: [TagTypes.SETTINGS_APPLICATION],
      query: () => ({
        url: '/api/settings/api_keys',
        method: 'GET',
      }),
    });

    const rotateApiKey = builder.mutation<Plato.API.UpdateSettingsApiKeysResponseData, void>({
      invalidatesTags: [TagTypes.SETTINGS_APPLICATION],
      query: () => ({
        url: '/api/settings/api_keys',
        method: 'POST',
      }),
    });

    return {
      getApiKeys,
      rotateApiKey,
    };
 } ,
});
